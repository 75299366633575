import React from 'react';
import { Divider, Typography, Grid, Paper, Container, Box, Button, CardMedia } from '@mui/material';
import Card from '@mui/material/Card';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { navigate } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Clients from './Clients';
import CardContent from '@mui/material/CardContent';
import { makeStyles } from '@mui/styles';
import watermarksImg from '../../images/maskCareers.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const useStyles = makeStyles(() => ({
    watermark: {
        float: 'right',
        position: 'absolute',
        width: '40%',
        height: '40%',
        right: '0px',
        marginTop: '-2rem',
        '@media (min-width: 320px) and (max-width:375px)': {
            width: '60% !important',
            height: '6% !important'
        },
        '@media (min-width: 375px) and (max-width:425px)': {
            width: '60% !important',
            height: '7% !important'
        },
        '@media (min-width: 425px) and (max-width:768px)': {
            width: '60% !important',
            height: '8% !important'
        },
        '@media (min-width: 768px) and (max-width:1024px)': {
            width: '38%!important',
            height: '17% !important'
        },
        '@media (min-width: 1024px) and (max-width:1039px)': {
            width: '38%!important',
            height: '29% !important'
        }
    }
}));

const CapabilityBanner = () => {
    const classes = useStyles();

    const data = useStaticQuery(graphql`
        query cardCapabilities {
            allCardJson {
                edges {
                    node {
                        heading
                        data
                        subtitle
                        subtitle1
                        images {
                            childImageSharp {
                                gatsbyImageData(width: 100)
                            }
                        }
                    }
                }
            }
        }
    `);

    const GetCards = (data) => {
        const cardArray = [];
        data.allCardJson.edges.map((item, index) => {
            const image = getImage(item.node.images);

            cardArray.push(
                <Grid
                    item
                    xs={6}
                    md={4}
                    sm={4}
                    classes="grid_shadow"
                    
                >
                    <Card variant="capability_card"
                    sx={{cursor: 'default'}}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={8} md={8} sm={8}>
                                <CardContent>
                                    <Typography variant="h3">{item.node.heading}</Typography>
                                </CardContent>
                            </Grid>
                            <Grid
                                item
                                xs={4}
                                sm={3}
                                md={4}
                                sx={{
                                    width: '50%',
                                    padding: '1rem',
                                    display: 'flex',

                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <CardMedia>
                                    <GatsbyImage image={image} alt="item.node.images" />
                                </CardMedia>
                            </Grid>
                            <Grid
                                sx={{
                                    display: 'flex',
                                    pb: '3px',
                                    pl: 4,
                                    marginTop: '-2rem'
                                }}
                            >
                                <Typography variant="bodyText1">{item.node.data}</Typography>
                            </Grid>
                        </Grid>
                    </Card>
                    <Box
                        sx={{
                            width: '30px',
                            height: '30px',
                            borderTop: '40px solid #D3F09A',
                            borderLeft: '40px solid transparent'
                        }}
                    ></Box>
                    <Grid
                     sx={{cursor: 'default'}}
                    >
                        <Paper variant="capability_banner_paper">
                            <Divider variant="card_divider" />

                            <Typography variant="bodyText8">{item.node.subtitle} <br/></Typography>
                            <Typography variant="bodyText8">{item.node.subtitle1}</Typography>
                            <Grid md={12}>
                                <Grid md={6}></Grid>
                                <Button variant="text"
                                onClick={() => {
                        if (item.node.heading === 'Product/Platform Engineering') {
                            navigate('/customer-engagement', {
                                replace: true,
                                state: { id: 0 }
                            });
                        } else if (item.node.heading === 'Technology Consulting') {
                            navigate('/customer-engagement', { state: { id: 1 } });
                        } else if (item.node.heading === 'Hybrid Mobile Apps') {
                            navigate('/customer-engagement', { state: { id: 2 } });
                        } else if (item.node.heading === 'Product Consulting') {
                            navigate('/customer-engagement', { state: { id: 3 } });
                        } else if (item.node.heading === 'Data Engineering') {
                            navigate('/customer-engagement', { state: { id: 4 } });
                        } else if (item.node.heading === 'Capacity Enhancement') {
                            navigate('/customer-engagement', { state: { id: 5 } });
                        }
                    }}
                                >
                                    Learn more
                                    <Typography
                                        sx={{
                                            marginTop: '3px',
                                            paddingLeft: '10px'
                                        }}
                                    >
                                        <StaticImage
                                            src="../../images/Icon.svg"
                                            placeholder="none"
                                        />
                                    </Typography>
                                </Button>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                //
            );
        });
        return cardArray;
    };

    return (
        <>
            <Box width="100%">
                <div>
                    <Paper variant="capability_banner">
                        <LazyLoadImage
                            fullWidth
                            src={watermarksImg}
                            className={classes.watermark}
                            alt="lazy"
                        />

                        <Container sx={{ position: 'relative' }}>
                            <Typography variant="h2">
                                Our{' '}
                                <span style={{ color: '#BECE20', letterSpacing: '2px' }}>
                                    {' '}
                                    <b>Capabilities</b>
                                </span>{' '}
                            </Typography>
                            <Typography component="h2" variant="bodyText3">
                            Engaged in Delivery of Software Development Services in the Geographies of North America, India & Australia
                                <br />
                                <Typography sx={{ display: { xs: 'flex', md: 'none', sm: 'none', lg: 'none' }, marginTop: '10px', }} />
                                Domains Spread Across  Education, Pharma, FinTech, Social Impact, Retail, Amongst Others.
                            </Typography>
                            <Grid
                                container
                                spacing={{ xs: 6, md: 6 }}
                                columns={{ xs: 4, sm: 8, md: 12 }}
                            >
                                {GetCards(data)}
                            </Grid>
                        </Container>
                    </Paper>

                    <br />

                    <Container>
                        <Clients />
                    </Container>
                </div>
            </Box>
        </>
    );
};
export default CapabilityBanner;
